const routes = [
  {
    path: "/",
    component: () => import("../views/ViewHome.vue"),
    meta: {
      title: "btn-kit • get started",
      description: "Vue 3 component for creating buttons for web applications",
    },
  },
  {
    path: "/build",
    component: () => import("../views/ViewBuild.vue"),
    meta: {
      title: "btn-kit • build",
      description: "Vue 3 component for creating buttons for web applications",
    },
  },
  {
    path: "/docs",
    component: () => import("../views/ViewDocs.vue"),
    meta: {
      title: "btn-kit • docs",
      description: "Vue 3 component for creating buttons for web applications",
    },
  },
  {
    path: "/error",
    component: () => import("../views/ViewError.vue"),
    meta: {
      title: "btn-kit • 404",
      description: "",
    },
  },
  // not found
  {
    path: "/:pathMatch(.*)*",
    redirect: "/error",
  },
];

export default routes;
