<script>
import { ref, onMounted } from "vue";

import axios from "axios";
import useMedia from "../store/media";

export default {
  name: "AppLogotype",
  setup() {
    const media = useMedia();
    const version = ref();

    onMounted(async () => {
      try {
        const response = await axios.get(
          "https://registry.npmjs.org/btn-kit/latest",
        );

        version.value = response.data.version;
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    });

    return {
      media,
      version,
    };
  },
};
</script>

<template lang="html">
  <NavLink class="logotype" to="/">
    <div class="logotype__icon">
      <AppIcon id="i-logotype" />
    </div>
    <div class="logotype__text">
      <span class="name">btn-kit </span>
      <span v-if="media.isDrawer" class="version">v{{ version }}</span>
    </div>
  </NavLink>
</template>

<style lang="scss" scoped>
@import "../assets/scss/base/mixins";

.logotype {
  --color-one: var(--c-chestnut);
  --color-two: var(--c-carmine);

  [data-theme="dark"] & {
    --color-one: var(--c-carmine);
    --color-two: var(--c-chestnut);
  }

  display: inline-flex;
  align-items: center;
  color: var(--c-text-rich);

  &__icon {
    .svg-icon {
      width: 20px;
      height: 20px;
      color: var(--color-one);
      fill: var(--color-two);

      @include min-width(drawer) {
        width: 42px;
        height: 42px;
      }
    }
  }

  &__text {
    position: relative;
    line-height: 1;

    .name {
      display: block;
      padding-left: 14px;
      font-size: 13px;
      font-weight: 600;
      white-space: nowrap;

      @include min-width(drawer) {
        padding-left: 21px;
        font-size: 18px;
      }
    }

    @include min-width(drawer) {
      .version {
        display: block;
        position: absolute;
        inset: 0;
        padding-left: 7px;
        transform: translate(100%, -1px);
        font-weight: 600;
        font-size: 10px;
        color: var(--c-text-pale);
      }
    }
  }
}
</style>
