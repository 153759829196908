import { createApp } from "vue";
import { createPinia } from "pinia";
import Vue3TouchEvents from "vue3-touch-events";

import AppIcon from "vue-sprite";
import AppButton from "btn-kit";
import NavLink from "vue-nav-link";

import App from "./App.vue";
import router from "./router";

const app = createApp(App);

app.use(createPinia());
app.use(router());
app.use(Vue3TouchEvents, { disableClick: true });
app.component("AppIcon", AppIcon);
app.component("AppButton", AppButton);
app.component("NavLink", NavLink);
app.mount("#app");
