<script>
import ToggleTheme from "vue-toggle-theme";

export default {
  name: "PackagesBar",
  components: { ToggleTheme },
};
</script>

<template>
  <div class="packages-bar">
    <AppButton
      aria-label="GitHub"
      theme="copy"
      size="md"
      variation="text"
      shape="rounded"
      href="https://github.com/ux-ui-pro/btn-kit"
    >
      <AppIcon id="i-git" />
    </AppButton>

    <AppButton
      aria-label="NPM"
      theme="copy"
      size="md"
      variation="text"
      shape="rounded"
      href="https://www.npmjs.com/package/btn-kit"
    >
      <AppIcon id="i-npm" />
    </AppButton>
  </div>
</template>

<style lang="scss" scoped>
@import "../assets/scss/base/mixins";

.packages-bar {
  margin-right: 2rem;

  @include max-width(drawer) {
    padding: 0 0.7rem;
    opacity: 0;
    transform: translateX(0);
    @include changeDrawerEls;

    body.is-drawer & {
      opacity: 1;
      transform: translateX(30px);
    }
  }
}
</style>
