<script>
import useDrawer from "../store/drawer";

export default {
  name: "AppOverlay",
  setup() {
    const drawer = useDrawer();

    const closeDrawer = () => {
      drawer.setCondition(false);
    };

    return {
      closeDrawer,
    };
  },
};
</script>

<template lang="html">
  <Teleport to="body">
    <div @click="closeDrawer" class="overlay"></div>
  </Teleport>
</template>

<style lang="scss" scoped>
@import "../assets/scss/base/mixins";

.overlay {
  pointer-events: none;
  position: fixed;
  z-index: var(--z-index-overlay);
  inset: 0;
  margin: auto;
  background-color: hsla(0 0% 5% / 0.65);
  opacity: 0;
  @include changeDrawerOpacity;

  body.is-drawer & {
    opacity: 1;
    pointer-events: auto;
  }
}
</style>
