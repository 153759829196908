<script>
export default {
  name: "LayoutFooter",
};
</script>

<template>
  <footer class="footer">
    <p class="paragraph-lead">
      <span class="text-smaller text-light">
        <a class="link-different" href="https://github.com/ux-ui-pro/"
          >GitHub</a
        >
        &bull;
        <a class="link-different" href="https://t.me/ace_coder">Telegram</a>
      </span>
    </p>
  </footer>
</template>

<style lang="scss" scoped>
@import "../assets/scss/base/mixins";

.footer {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 3rem;
  height: var(--footerHeight);
  color: var(--c-text-pale);

  @include borderSide(top);
}
</style>
