import { defineStore } from "pinia";

const useDrawer = defineStore({
  id: "useDrawer",
  state: () => ({
    condition: false,
  }),
  actions: {
    setCondition(value) {
      this.condition = value;
    },
  },
});

export default useDrawer;
