import Lenis from "@studio-freight/lenis";

const scrolling = {
  lenis: null,
  smooth: {
    create() {
      this.lenis = new Lenis({
        smoothWheel: true,
        duration: 1.5,
        easing: (t) => Math.min(1, 1.001 - 2 ** (-15 * t)),
      });

      const scrollSmooth = (time) => {
        if (!this.lenis) return;

        this.lenis.raf(time);
        requestAnimationFrame(scrollSmooth);
      };

      requestAnimationFrame(scrollSmooth);
    },
    destroy() {
      if (!this.lenis) return;

      this.lenis.destroy();
      this.lenis = null;
    },
    start() {
      if (!this.lenis) return;

      this.lenis.start();
    },
    stop() {
      if (!this.lenis) return;

      this.lenis.stop();
    },
  },
};

export default scrolling;
