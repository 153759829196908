const mediaTypes = new Map([
  ["touch", "(min-width: 1200px) and (any-pointer: fine)"],
  ["breakpoint", "(min-width: 1200px)"],
  ["drawer", "(min-width: 1500px)"],
]);

function mediaQuery(mediaType) {
  return mediaTypes.get(mediaType) || "";
}

export default mediaQuery;
