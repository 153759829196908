<script>
import useMedia from "../store/media";

import PackagesBar from "./PackagesBar.vue";
import AppTheme from "./AppTheme.vue";

export default {
  name: "HeaderExtra",
  components: {
    PackagesBar,
    AppTheme,
  },
  setup() {
    const media = useMedia();

    return {
      media,
    };
  },
};
</script>

<template>
  <div class="header-extra">
    <PackagesBar v-if="media.isDrawer" />

    <AppTheme />
  </div>
</template>

<style lang="scss" scoped>
@import "../assets/scss/base/mixins";

.header-extra {
  display: flex;
  align-items: center;
}
</style>
