<script>
import { ref, watchEffect } from "vue";
import useDrawer from "../store/drawer";

export default {
  name: "AppHamburger",
  setup() {
    const drawer = useDrawer();
    const isThrottled = ref(true);

    const toggleDrawer = () => {
      if (isThrottled.value) {
        isThrottled.value = false;

        setTimeout(() => {
          isThrottled.value = true;
        }, 700);

        const newCondition = !drawer.condition;
        drawer.setCondition(newCondition);
      }
    };

    watchEffect(() => {
      if (drawer.condition) {
        document.body.classList.add("is-drawer");
      } else {
        document.body.classList.remove("is-drawer");
      }
    });

    return {
      toggleDrawer,
    };
  },
};
</script>

<template lang="html">
  <Teleport to="body">
    <button
      @click="toggleDrawer"
      class="hamburger"
      aria-expanded="false"
      aria-label="Меню"
      role="button"
    >
      <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 40h62c18 0 18-20-17 5L31 55" />
        <path d="M0 50h80" />
        <path d="M0 60h62c18 0 18 20-17-5L31 45" />
      </svg>
    </button>
  </Teleport>
</template>

<style lang="scss" scoped>
@import "../assets/scss/base/mixins";

.hamburger {
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--z-index-hamburger);
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  text-transform: none;
  border: none;
  background: none;
  -webkit-appearance: button;
  overflow: hidden;
  width: 50px;
  height: 50px;
  display: grid;
  place-content: center;
  @include changeDrawerEls;
  transition-delay: 0s;

  body.is-drawer & {
    transform: translateX(36px);
  }

  svg {
    width: 80px;
    height: 80px;
  }

  path {
    --length: 24;
    --offset: -38;

    fill: none;
    stroke: var(--c-text-rich);
    stroke-width: 2.4;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-dasharray: var(--length) var(--total-length);
    stroke-dashoffset: var(--offset);
    @include changeHamburger;

    &:nth-child(1),
    &:nth-child(3) {
      --total-length: 126.38166809082031;
    }

    &:nth-child(2) {
      --total-length: 80;
    }
  }

  body.is-drawer & {
    path {
      &:nth-child(1),
      &:nth-child(3) {
        --length: 8.602325267;
        --offset: -109.1770175568;
      }
    }
  }
}
</style>
