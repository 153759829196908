<script>
import useMedia from "../store/media";

import AppLogotype from "../components/AppLogotype.vue";
import NavMain from "../components/NavMain.vue";
import AppHamburger from "../components/AppHamburger.vue";
import HeaderExtra from "../components/HeaderExtra.vue";

export default {
  name: "LayoutHeader",
  components: {
    AppLogotype,
    NavMain,
    AppHamburger,
    HeaderExtra,
  },
  setup() {
    const media = useMedia();

    return {
      media,
    };
  },
};
</script>

<template>
  <header class="header">
    <div class="header__container">
      <AppHamburger v-if="!media.isDrawer" />
      <AppLogotype />
      <NavMain v-if="media.isDrawer" />
      <HeaderExtra />
    </div>
  </header>
</template>

<style lang="scss" scoped>
@import "../assets/scss/base/mixins";

.header {
  position: sticky;
  z-index: var(--z-index-header);
  top: 0;
  margin: auto;
  background-color: var(--c-background-primary);

  @include fixJumpingScrollbar;

  @include min-width(drawer) {
    position: relative;
    top: auto;
  }

  &__container {
    width: 100%;
    height: var(--headerHeight);
    margin-left: auto;
    margin-right: auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    grid-gap: 0;
    align-items: center;

    @include min-width(drawer) {
      width: var(--wrapper);
    }
  }

  .logotype {
    grid-column: 2 / 3;
    justify-self: center;

    @include min-width(drawer) {
      grid-column: 1 / 2;
      justify-self: start;
    }
  }

  .nav-main {
    grid-column: 2 / 3;
    justify-self: center;
  }

  .header-extra {
    grid-column: 3 / 4;
    justify-self: end;
  }

  @include borderSide(bottom);
}
</style>
