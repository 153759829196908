<script>
import { ref, reactive } from "vue";

export default {
  name: "NavMain",
  setup() {
    const isThrottled = ref(true);

    const navItems = reactive([
      { text: "Get started", to: "/" },
      { text: "Build", to: "/build" },
      { text: "Docs", to: "/docs" },
      { text: "Sandbox", to: "https://codesandbox.io/s/btn-kit-npm-qpgzlf" },
    ]);

    const handleClick = () => {
      if (isThrottled.value) {
        isThrottled.value = false;

        setTimeout(() => {
          isThrottled.value = true;
        }, 750);
      }
    };

    return {
      navItems,
      handleClick,
      isThrottled,
    };
  },
};
</script>

<template lang="html">
  <nav class="nav-main">
    <template v-for="navItem in navItems">
      <NavLink
        v-if="navItem.text !== 'Sandbox'"
        :key="navItem.to"
        :to="navItem.to"
        :class="{
          'route-transition': !isThrottled,
          'nav-main__item': true,
          'main-link': true,
        }"
        @click="handleClick"
      >
        <span>{{ navItem.text }}</span>
      </NavLink>

      <a
        v-else
        :key="navItem.to"
        :href="navItem.to"
        class="nav-main__item main-link"
        target="_blank"
      >
        <span>{{ navItem.text }}</span>
      </a>
    </template>
  </nav>
</template>

<style lang="scss" scoped>
@import "../assets/scss/base/mixins";

.nav-main {
  display: inline-flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  padding: 9rem 0 5rem;
  user-select: none;

  @include min-width(drawer) {
    flex-direction: row;
    justify-content: center;
    padding: 0;
  }

  &__item {
    -webkit-tap-highlight-color: transparent;

    @include max-width(drawer) {
      height: 5rem;
      display: inline-flex;
      align-items: center;
      padding: 0 2rem;
      opacity: 0;
      transform: translateX(0);
      @include changeDrawerEls;

      body.is-drawer & {
        opacity: 1;
        transform: translateX(30px);
      }
    }

    @include min-width(drawer) {
      height: 5rem;
      padding: 0 1.8rem;
      display: inline-flex;
      align-items: center;
    }

    &:last-child {
      position: relative;

      &::after {
        content: "";
        position: absolute;
        inset: 0 2px 0 auto;
        margin: auto;
        transform: translateY(2px);
        display: block;
        width: 8px;
        height: 8px;
        background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI4IiBoZWlnaHQ9IjgiIGZpbGw9Im5vbmUiPjxwYXRoIGZpbGw9IiM5Njk2OTYiIGQ9Ik0yLjA1LjVBLjQ1LjQ1IDAgMCAxIDIuNS4wNWg1YS40NS40NSAwIDAgMSAuNDUuNDV2NWEuNDUuNDUgMCAxIDEtLjkgMFYxLjcwN0wuOTMgNy44MjhhLjUzNS41MzUgMCAwIDEtLjc1Ny0uNzU2TDYuMjk1Ljk1SDIuNUEuNDUuNDUgMCAwIDEgMi4wNS41WiIvPjwvc3ZnPg==)
          center right / 8px 8px no-repeat;

        @include min-width(drawer) {
          transform: translateY(1px);
        }

        [data-theme="dark"] & {
          background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI4IiBoZWlnaHQ9IjgiIGZpbGw9Im5vbmUiPjxwYXRoIGZpbGw9IiM2RjZGNzEiIGQ9Ik0yLjA1LjVBLjQ1LjQ1IDAgMCAxIDIuNS4wNWg1YS40NS40NSAwIDAgMSAuNDUuNDV2NWEuNDUuNDUgMCAxIDEtLjkgMFYxLjcwN0wuOTMgNy44MjhhLjUzNS41MzUgMCAwIDEtLjc1Ny0uNzU2TDYuMjk1Ljk1SDIuNUEuNDUuNDUgMCAwIDEgMi4wNS41WiIvPjwvc3ZnPg==)
            center right / 8px 8px no-repeat;
        }
      }
    }
  }
}
</style>
