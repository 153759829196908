<script>
import { ref, watchEffect, computed } from "vue";
import { useRouter, useRoute } from "vue-router";
import anime from "animejs";
import useMedia from "../store/media";
import useDrawer from "../store/drawer";

import LayoutHeader from "./LayoutHeader.vue";
import LayoutFooter from "./LayoutFooter.vue";
import AppOverlay from "../components/AppOverlay.vue";
import AppDrawer from "../components/AppDrawer.vue";

export default {
  name: "LayoutMain",
  components: {
    LayoutHeader,
    LayoutFooter,
    AppOverlay,
    AppDrawer,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const media = useMedia();
    const drawer = useDrawer();
    const isFirstLoad = ref(true);
    const mainRef = ref(null);
    const mainAnim = ref(null);

    const routeLeave = async () => {
      if (!isFirstLoad.value) {
        mainAnim.value = anime
          .timeline({
            loop: false,
            autoplay: false,
          })
          .add({
            targets: mainRef.value,
            easing: media.isBreakpoint ? "easeInQuad" : "easeInQuad",
            translateX: media.isBreakpoint ? ["0", "150"] : null,
            opacity: ["1", "0"],
            duration: media.isBreakpoint ? 450 : 400,
          });

        await mainAnim.value.play();

        await mainAnim.value.finished; // ожидание окончания анимации перед переходом на новую страницу
      }
    };

    const routeEnter = async () => {
      if (isFirstLoad.value) {
        isFirstLoad.value = false;
      } else {
        mainAnim.value = anime
          .timeline({
            loop: false,
            autoplay: false,
          })
          .add({
            targets: mainRef.value,
            easing: media.isBreakpoint ? "easeOutQuad" : "easeOutQuad",
            translateX: media.isBreakpoint ? ["-150", "0"] : null,
            opacity: ["0", "1"],
            duration: media.isBreakpoint ? 450 : 400,
          });

        await mainAnim.value.play();
      }
    };

    const mainStylingClass = computed(() => {
      return route.path === "/" ? "" : `main--${route.path.slice(1)}`;
    });

    watchEffect(() => {
      router.beforeEach(async (to, from, next) => {
        await drawer.setCondition(false);
        await routeLeave();

        next();
      });

      router.afterEach(async () => {
        await routeEnter();
      });
    });

    return {
      media,
      mainRef,
      mainStylingClass,
    };
  },
};
</script>

<template>
  <LayoutHeader />
  <main class="main" :class="mainStylingClass" ref="mainRef">
    <slot />
  </main>
  <LayoutFooter />
  <AppOverlay v-if="!media.isDrawer" />
  <AppDrawer v-if="!media.isDrawer" />
</template>

<style lang="scss" scoped>
.main {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transform-style: preserve-3d;
  backface-visibility: hidden;
}
</style>
