<script>
import { watch, onMounted } from "vue";
import { useRouter } from "vue-router";
import useMedia from "./store/media";
import scrolling from "./utils/lenis";

import LayoutMain from "./layout/LayoutMain.vue";

export default {
  name: "App",
  components: {
    LayoutMain,
  },
  setup() {
    const router = useRouter();

    onMounted(() => {
      scrolling.smooth.create();
      useMedia().initMediaTrigger();
    });

    watch(
      () => router.currentRoute.value,
      (to) => {
        document.title = to.meta.title;
        document
          .querySelector('meta[name="description"]')
          .setAttribute("content", to.meta.description);
      },
    );
  },
};
</script>

<template>
  <LayoutMain>
    <routerView></routerView>
  </LayoutMain>
</template>

<style lang="scss">
@import "./assets/scss/base/mixins";

#app {
  display: flex;
  flex-direction: column;
  max-width: 100vw;
  min-height: 100vh;
  overflow-y: clip;
}
</style>
