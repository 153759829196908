<script>
import { ref, watchEffect } from "vue";
import { lock, unlock } from "tua-body-scroll-lock";
import useDrawer from "../store/drawer";

import NavMain from "./NavMain.vue";
import PackagesBar from "./PackagesBar.vue";

export default {
  name: "AppDrawer",
  components: {
    NavMain,
    PackagesBar,
  },
  setup() {
    const drawer = useDrawer();
    const scrollerRef = ref();

    watchEffect(() => {
      if (drawer.condition) {
        lock(scrollerRef.value, { overflowType: "clip" });
      } else {
        unlock(scrollerRef.value);
      }
    });

    const swipeHandler = () => {
      drawer.setCondition(false);
    };

    return {
      scrollerRef,
      swipeHandler,
    };
  },
};
</script>

<template lang="html">
  <Teleport to="body">
    <div class="drawer">
      <div
        ref="scrollerRef"
        v-touch:swipe.left="swipeHandler"
        class="drawer__scroller"
      >
        <NavMain />

        <PackagesBar />
      </div>
    </div>
  </Teleport>
</template>

<style lang="scss" scoped>
@import "../assets/scss/base/mixins";

.drawer {
  position: fixed;
  z-index: var(--z-index-drawer);
  top: 0;
  left: 0;
  margin: auto;
  background-color: var(--c-background-primary);
  width: 280px;
  height: 100vh;
  transform: translateX(-300px);
  @include changeDrawerPanel;

  body.is-drawer & {
    transform: translateX(0);
  }

  &__scroller {
    position: relative;
    width: 100%;
    height: 100%;
    padding-bottom: 7rem;
    overflow: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
}
</style>
