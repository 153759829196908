import { computed as i, resolveComponent as l, openBlock as s, createBlock as u, withCtx as p, renderSlot as c, createElementBlock as _ } from "vue";
import { useRoute as f } from "vue-router";
const d = (t, n) => {
  const e = t.__vccOpts || t;
  for (const [o, r] of n)
    e[o] = r;
  return e;
}, k = {
  props: {
    to: {
      type: [String, Object],
      required: !0
    }
  },
  setup(t) {
    const n = f();
    return {
      isActive: i(() => {
        const o = typeof t.to == "string" ? t.to : t.to.path;
        return n.path === o;
      })
    };
  }
}, v = {
  key: 1,
  class: "nav-link active"
};
function m(t, n, e, o, r, h) {
  const a = l("router-link");
  return o.isActive ? (s(), _("span", v, [
    c(t.$slots, "default")
  ])) : (s(), u(a, {
    key: 0,
    to: e.to,
    class: "nav-link"
  }, {
    default: p(() => [
      c(t.$slots, "default")
    ]),
    _: 3
  }, 8, ["to"]));
}
const g = /* @__PURE__ */ d(k, [["render", m]]);
export {
  g as default
};
