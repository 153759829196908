import { defineStore } from "pinia";
import MediaTrigger from "media-trigger";
import mediaQuery from "../utils/mediaQuery";

const useMedia = defineStore({
  id: "useMedia",
  state: () => ({
    isDrawer: true,
    isBreakpoint: true,
    isTouch: true,
  }),
  actions: {
    setIsDrawer(value) {
      this.isDrawer = value;
    },
    setIsBreakpoint(value) {
      this.isBreakpoint = value;
    },
    setIsTouch(value) {
      this.isTouch = value;
    },
    initMediaTrigger() {
      this.createMediaTrigger(
        "drawer",
        () => {
          this.setIsDrawer(true);
        },
        () => {
          this.setIsDrawer(false);
        },
      );
      this.createMediaTrigger(
        "breakpoint",
        () => {
          this.setIsBreakpoint(true);
        },
        () => {
          this.setIsBreakpoint(false);
        },
      );
      this.createMediaTrigger(
        "touch",
        () => {
          this.setIsTouch(true);
        },
        () => {
          this.setIsTouch(false);
        },
      );
    },
    createMediaTrigger(media, entry, exit) {
      return new MediaTrigger({
        media: mediaQuery(media),
        entry,
        exit,
      });
    },
  },
});

export default useMedia;
